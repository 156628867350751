import { FC, Suspense, memo } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { MessageSquare } from "react-feather";
import { useHistory } from "react-router";

import { CanonicalContent, Contact, Message, Share, Unfurl } from "../../generated/graphql";
import { relativize } from "../../utils/url";
import { canonicalLink } from "../../utils/routes";
import { External as ExternalUnfurl } from '../unfurl/External';
import { ShareText, ShareTextProps } from "../ShareText";
import { InlineShare, InlineShareProps } from "../../views/feed/items/InlineShare";
import { ListShare, ListShareProps } from "./ListShare";
import { Link } from "../Link";
import { Image } from "../Image";
import { LikeButton, LikeButtonProps } from "./LikeButton";
import { ReactionsText, ReactionsTextProps } from "../ReactionsText";
import { CompactUnfurl, CompactUnfurlProps } from "../unfurl/CompactUnfurl";


export type MessageCardProps = {
  share: Pick<Share, '_id' | 'commentCount' | 'deleted'> & {
    sender?: Pick<Contact, '_id'> | null,
    canonicalContent?: Pick<CanonicalContent, '_id'> | null,
    reshareOf?: Pick<Share, '_id'> & Omit<InlineShareProps['share'], 'unfurl'> | null,
    unfurl: Pick<Unfurl, 'link' | 'title'> & InlineShareProps['share']['unfurl'],
    message?: Pick<Message, 'link'> | null,
    reactions: ReactionsTextProps['reactions'],
  } & ShareTextProps['share'] & LikeButtonProps['share'] & CompactUnfurlProps['share'];
  canonical?: InlineShareProps['canonicalContent'] & CompactUnfurlProps['canonicalContent'],
  list?: ListShareProps['list'] | null,
  hideUnfurl?: boolean,
  showCommentView?: string,
  impressionRef?: ((node?: Element | null | undefined) => void),
  setShowCommentView: (shareId: string) => void,
  externalLink?: boolean,
};


export const MessageCard: FC<MessageCardProps> = memo(function MessageCard({ share, canonical, list, hideUnfurl, impressionRef, setShowCommentView, externalLink=true }: MessageCardProps) {

  const theme = useTheme();
  const history = useHistory();

  return (
    <Box sx={{ mx: 2, py: 0 }}>

      <ShareText share={share} collapse={false} sx={{ pb: 0.5 }} />

      {share.deleted &&
        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
          This post has been deleted.
        </Typography>
      }

      {share.reshareOf &&
        <Box sx={{ mb: 1 }}>
          <InlineShare share={{ ...share.reshareOf, unfurl: share.unfurl }} canonicalContent={canonical} showUnfurl={false} handleClick={() => history.push(canonicalLink(share.canonicalContent?._id, share.reshareOf?._id)) } />
        </Box>
      }

      {!hideUnfurl &&
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '85%' }}>
            {share.unfurl?.link && externalLink &&
              <Link
                ref={impressionRef}
                link={share.unfurl.link}
              >
                <ExternalUnfurl
                  title={share.unfurl?.title || undefined}
                  link={share.unfurl?.link}
                  sx={{ border: 'none', backgroundColor: 'grey.100', width: '100%' }}
                  image={share.unfurl?.images?.[0] && <Image {...share.unfurl.images[0]} constraints='128x128_c' width={128} height={128} />}
                />
              </Link>
            }
            {!externalLink &&
              <CompactUnfurl share={share} canonicalContent={canonical} sx={{ border: 'none', backgroundColor: 'grey.100', width: '100%' }} />
            }
          </Box>
        </Box>
      }

      {list && share.message?.link &&
        <Box>
          <ListShare list={list} sx={{ backgroundColor: 'grey.100' }} link={relativize(share.message.link)} showUser={list.createdBy?._id !== share.sender?._id} />
        </Box>
      }

      {!share.deleted &&
        <Suspense fallback={<div></div>}>
          <Box sx={{ pt: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', gap: share.reactions && share.reactions.length > 0 ? 1 : 2 }}>
            <LikeButton share={share} sx={{ flex: '1 1 auto', backgroundColor: 'grey.50', borderRadius: 0.5, px: 0.5 }} showText />
            <Typography
              variant="body2"
              color="text.secondary"
              noWrap
              sx={{ flex: '1 1 auto', backgroundColor: 'grey.50', borderRadius: 0.5, px: 0.5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={() => setShowCommentView(share._id)}
              data-cy="add-comment"
            >
              <MessageSquare
                style={{
                  stroke: theme.palette.grey[500],
                  marginRight: '4px',
                }}
                width={14}
                height={14}
                />
              {share.commentCount === 0 &&
                <>Comment</>
              }
              {share.commentCount === 1 &&
                <>{share.commentCount} Comment</>
              }
              {share.commentCount > 1 &&
                <>{share.commentCount} Comments</>
              }
            </Typography>
          </Box>
          {share.reactions && share.reactions.length > 0 &&
            <>
              <Divider sx={{ width: '100%', pt: 1, mb: 0.5, borderColor: 'grey.100' }}/>
              <ReactionsText reactions={share.reactions} />
            </>
          }
        </Suspense>
      }
    </Box>
  );
});

import { CSSProperties, FC, memo, useEffect, useState } from "react";
import { BlurhashCanvas } from "react-blurhash";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "./Helmet";


// Moved outside component so Blurhash props becomes pure
const canvasStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '100%',
} as const;


export type ImageProps = {
  constraints: string;
  alt: string;
  hash: string;
  blurhash?: string | null;
  width: number;
  height: number;
  style?: React.CSSProperties;
  loading?: 'lazy' | 'eager';
  onClick?: (ev: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  objectFit?: CSSProperties['objectFit'];
  className?: string;
  preload?: boolean;
};


export const Image: FC<ImageProps> = memo(function Image({ constraints, alt, hash, blurhash, width, height, style, loading='eager', onClick, objectFit='cover', className='', preload=false }: ImageProps) {

  const theme = useTheme();

  const aspect = width/height;

  const imageUrl = `${process.env.REACT_APP_IMAGE_BASE}/${hash}_${constraints}`;

  const [image, setImage] = useState<string | undefined>(imageUrl);

  useEffect(() => {
    if(image === imageUrl) {
      // console.warn('Image component useEffect update with same image url');
    } else if(image === undefined) {
      setImage(imageUrl);
    } else {
      setImage(undefined);
      setTimeout(() => {
        setImage(imageUrl);
      }, 1000/60);
    }
  }, [imageUrl, image]);

  // no reason to show blurhash on small images
  const showBlurhash = blurhash && width > 128 && height > 128;

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        paddingTop: 1/aspect*100 + '%',
        backgroundColor: theme.palette.grey[500],
        ...style,
      }}
      className={className}
    >
      {preload &&
        <Helmet>
          <link rel='preload' href={`${process.env.REACT_APP_IMAGE_BASE}/${hash}_${constraints}.avif`} as='image' type="image/avif" />
        </Helmet>
      }
      {showBlurhash &&
        <div style={{ display: 'inline-block', width: '100%', height: '100%', inset: 0, position: 'absolute' }}>
          <BlurhashCanvas
            hash={blurhash}
            height={32}
            width={Math.round(32*aspect)}
            punch={1}
            style={canvasStyle}
          />
        </div>
      }
      <picture>
        <source type="image/avif" srcSet={image && `${image}.avif`} />
        <source type="image/webp" srcSet={image && `${image}.webp`} />
        <img
          style={{
            visibility: image ? 'visible' : 'hidden',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            objectFit,
          }}
          src={image && `${image}.jpg`}
          alt={alt}
          loading={loading}
          onClick={onClick || undefined}
        />
      </picture>
    </div>
  );
});

import { FC, memo, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';


export type StaticLayoutHeaderProps = {
  title: ReactNode,
  headerColor: string,
  headerHeight: number,
  headerContent?: ReactNode,
  headerContentHeight?: number,
  backButton?: boolean | ReactNode,
  action?: ReactNode,
}; 


export const StaticLayoutHeader: FC<StaticLayoutHeaderProps> = memo(function StaticLayoutHeader({ title, headerColor, headerHeight, headerContent, headerContentHeight, backButton, action }: StaticLayoutHeaderProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 2,
        width: '100%',
        height: headerHeight + (headerContentHeight || 0),
        borderBottom: theme.other.feedBorder,
        backgroundColor: headerColor,
      }}
    >

      <Box sx={{
        height: headerHeight,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Box sx={{ height: '100%', minWidth: 48, flex: '0 0 auto', display: 'flex' }}>
          {backButton}
        </Box>

        <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
          <Box component='span' sx={{ flex: '1', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {title || <>&nbsp;</>}
          </Box>
        </Typography>

        <Box sx={{ height: '100%', width: 48, flex: '0 0 auto', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
          {action}
        </Box>
      </Box>
      {headerContent &&
        <Box sx={{ height: headerContentHeight }}>
          {headerContent}
        </Box>
      }
    </Box>
  );
});

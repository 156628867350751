import { forwardRef } from 'react';
import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material';


type TabsProps = MuiTabsProps & {
  size?: 'small' | 'medium',
}

export const Tabs = forwardRef<HTMLDivElement, TabsProps>(function Tabs({ value, onChange, size='medium', children, ...props }: TabsProps, ref) {

  const sizeSx: Record<string, unknown> = {};

  if(size === 'small') {
    sizeSx.minHeight = 42;
    sizeSx.height = 42;
    sizeSx['& .MuiTab-root'] = {
      minHeight: 40,
      pt: '12px',
      pb: '8px',
    }
  }

  return (
    <MuiTabs
      value={value}
      onChange={onChange}
      textColor="inherit"
      variant="fullWidth"
      {...props}
      ref={ref}

      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 2,
        backgroundColor: 'background.default',
        height: '48px',
        borderWidth: 0,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderColor: 'grey.200',
        '& .Mui-selected': {
          backgroundColor: 'background.paper',
        },
        '& .MuiTabs-indicator': {
          backgroundColor: 'text.primary',
        },
        ...sizeSx,
        ...(props.sx || {})
      }}


    >
      {children}
    </MuiTabs>
  );
});

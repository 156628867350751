import { FC, memo } from 'react';
import { Box } from '@mui/material';

import { Avatar, AvatarProps } from './Avatar';
import { useSession } from '@/hooks/auth';


export type AvatarStackProps = {
  contacts: AvatarProps['contact'][],
  size?: 'small' | 'medium',
  borderColor?: string,
} & Omit<AvatarProps, 'contact' | 'size'>


type StackedAvatarProps = {
  contact: AvatarProps['contact'],
  avatarSize: number,
  borderWidth: number,
  fontSize: string,
  borderColor: string,
  sx: AvatarProps['sx'],
}


const StackedAvatar: FC<StackedAvatarProps> = function StackedAvatar({ contact, avatarSize, borderWidth, fontSize, borderColor, sx }) {
  return (
    <Avatar
      contact={contact}
      sx={{
        width: avatarSize + 2 * borderWidth,
        height: avatarSize + 2 * borderWidth,
        fontSize,
        borderWidth,
        borderColor,
        borderStyle: 'solid',
        '&&&.MuiAvatar-root': {
          borderWidth,
        },
        ...sx,
      }}
    />
  );
}

export const AvatarStack: FC<AvatarStackProps> = memo(function AvatarStack({ contacts, size='medium', borderColor='background.paper', ...rest }: AvatarStackProps) {

  const { user } = useSession();

  let containerSize = 40;
  let avatarSize = 24;
  let borderWidth = 3;
  let fontSize = '14px';

  if(size === 'small') {
    containerSize = 30;
    avatarSize = 20;
    borderWidth = 2;
    fontSize = '14px';
  }

  const stackedAvatarProps = {
    avatarSize,
    borderWidth,
    fontSize,
    borderColor,
  };

  // filter out self from contacts
  if(user) {
    contacts = contacts.filter(c => {
      if(typeof c === 'string') {
        return c !== user._id;
      }
      return c?._id !== user._id;
    });
    // Add self back to the end of the list if it is a group (ie more than 1 other contact)
    if(contacts.length > 1) {
      contacts.push(user);
    }
  }

  if(contacts.length === 1) {
    return (
      <Avatar
        {...rest}
        contact={contacts[0]}
        size={size}
        sx={{
          width: containerSize,
          height: containerSize,
          fontSize: 18,
          ...rest.sx,
        }}
      />
    )
  } else if(contacts.length === 2) {
    return (
      <Box
        {...rest}
        sx={{
          position: 'relative',
          width: containerSize + 2 * borderWidth,
          height: containerSize + 2 * borderWidth,
          margin: -1 * borderWidth + 'px',
          flex: '0 0 auto',
          ...rest.sx,
        }}
      >
        <StackedAvatar
          contact={contacts[1]}
          {...stackedAvatarProps}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
          />
        <StackedAvatar
          contact={contacts[0]}
          {...stackedAvatarProps}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      </Box>
    );
  } else if(contacts.length === 3) {
    return (
      <Box
        {...rest}
        sx={{
          position: 'relative',
          width: containerSize + 2 * borderWidth,
          height: containerSize + 2 * borderWidth,
          margin: -1 * borderWidth + 'px',
          flex: '0 0 auto',
          ...rest.sx,
        }}
      >

        <StackedAvatar
          contact={contacts[2]}
          {...stackedAvatarProps}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        />
        <StackedAvatar
          contact={contacts[1]}
          {...stackedAvatarProps}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%)',
          }}
        />
        <StackedAvatar
          contact={contacts[0]}
          {...stackedAvatarProps}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />

      </Box>
    );
  } else if(contacts.length > 3) {
    return (
      <Box
        {...rest}
        sx={{
          position: 'relative',
          width: containerSize + 2 * borderWidth,
          height: containerSize + 2 * borderWidth,
          margin: -1 * borderWidth + 'px',
          flex: '0 0 auto',
          ...rest.sx,
        }}
      >

        <StackedAvatar
          contact={contacts[1]}
          {...stackedAvatarProps}
          borderColor='transparent'
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%)',
            backgroundClip: 'padding-box',
          }}
        />
        <StackedAvatar
          contact={contacts[0]}
          {...stackedAvatarProps}
          borderColor='transparent'
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundClip: 'padding-box',
          }}
        />

        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: avatarSize + 2 * borderWidth,
            height: avatarSize + 2 * borderWidth,
            fontSize: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
            color: 'white',
            borderRadius: '50%',
            border: '3px solid transparent',
            backgroundClip: 'padding-box',
            '&&&.MuiAvatar-root': {
              borderWidth,
            }
          }}
        >
          +{contacts.length - 2}
        </Box>

      </Box>
    );
  }

  console.error('AvatarStack: invalid number of contacts', contacts);
  return null;
});

import { FC, memo, SyntheticEvent, useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';

import { Home } from './icons/Home';
import { Explore } from './icons/Explore';
import { Share } from './icons/Share';
import { Saved } from './icons/Saved';
import { Friends } from './icons/Friends';
import { Kawaii } from './icons/Kawaii';


type ButtonProps = { selected: boolean, onClick: (ev: SyntheticEvent) => void, sx: SxProps };


const HomeButton: FC<ButtonProps> = memo(function HomeButton({ selected, onClick, sx }: ButtonProps) {
  const theme = useTheme();
  return (
    <Box className={`bottom-nav-tab ${selected && 'selected'}`} data-value='feed' sx={sx} onClick={onClick}>
      <Home color={theme.palette.text.primary} selected={selected} />
      Home
    </Box>
  );
});


const SavedButton: FC<ButtonProps> = memo(function SavedButton({ selected, onClick, sx }: ButtonProps) {
  const theme = useTheme();
  return (
    <Box className={`bottom-nav-tab ${selected && 'selected'}`} data-value='bookmarks' sx={sx} onClick={onClick}>
      <Saved color={theme.palette.text.primary} selected={selected} />
      Saved
    </Box>
  );
});


const ShareButton: FC<ButtonProps> = memo(function ShareButton({ selected, onClick, sx }: ButtonProps) {
  const theme = useTheme();
  return (
    <Box className={`bottom-nav-tab ${selected && 'selected'}`} data-value='share' sx={sx} style={{ paddingTop: '8px' }} onClick={onClick}>
      <Share style={{ width: '32px', height: '32px', marginBottom: '4px' }} backgroundFill={theme.palette.primary.light} foregroundFill={theme.palette.primary.main} arrowStroke={theme.palette.primary.contrastText} />
      Share
    </Box>
  );
});


const ExploreButton: FC<ButtonProps> = memo(function ExploreButton({ selected, onClick, sx }: ButtonProps) {
  const theme = useTheme();
  return (
    <Box className={`bottom-nav-tab ${selected && 'selected'}`} data-value='explore' sx={sx} onClick={onClick}>
      <Explore color={theme.palette.text.primary} style={{ width: '28px', height: '24px' }} selected={selected} />
      Explore
    </Box>
  );
});


const FriendsButton: FC<ButtonProps> = memo(function FriendsButton({ selected, onClick, sx }: ButtonProps) {
  const theme = useTheme();
  return (
    <Box className={`bottom-nav-tab ${selected && 'selected'}`} data-value='groups' sx={sx} onClick={onClick}>
      <Friends color={theme.palette.text.primary} selected={selected} />
      Friends
    </Box>
  );
});


type LogoProps = { onClick: (ev: SyntheticEvent) => void };
const Logo: FC<LogoProps> = memo(function Logo({ onClick }: LogoProps) {
  return (
    <Kawaii
      className='kawaii'
      data-value='feed'
      onClick={onClick}
      sx={{
        display: 'none',
        width: '48px',
        height: '48px',
        alignSelf: 'center',
        my: 2,
      }}
    />
  );
});


const BottomNav = memo(function BottomNav() {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const tabStyle: SxProps = useMemo(() => ({
    flex: '1 1 0px',
    // minWidth: 0,
    display: 'flex',
    minWidth: 60,
    minHeight: 72,
    [theme.breakpoints.up('sm')]: {
      minWidth: 96,
    },
    '& svg': {
      height: 24,
      width: 24,
      marginBottom: '8px',
    },
    padding: '12px 12px 0 12px',
    fontSize: 10,
    lineHeight: '12.5px',
    paddingBottom: 0,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.text.secondary,
    fontWeight: '500',
    '&.selected': {
      color: theme.palette.text.primary,
    },
    // '& .MuiTab-iconWrapper': {
    //   marginBottom: '8px',
    // },
  }), [theme]);

  const appBarStyle: SxProps = useMemo(() => ({
    [`@media (min-width: ${theme.breakpoints.values['sm'] + 2 * (96 + 8)}px)`]: {
      '--width': '96px',
      '--gap': '8px',
      left: 0,
      top: 0,
      width: 'var(--width)',
      transform: `translate(calc(50vw - (${theme.breakpoints.values['sm']}${theme.breakpoints.unit}/2) - var(--width) - var(--gap)))`,
      boxShadow: 'none',
      '& .bottom-nav-tabs': {
        flexDirection: 'column',
      },
      '& .kawaii': {
        display: 'block',
      },
    },
    [`@media (max-width: ${theme.breakpoints.values['sm'] + 2 * (96 + 8)}px)`]: {
      translate: "0 0",
      'body.scroll-down &': {
        translate: "0 100%",
      },
    },

    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    bottom: -1, // extend outside to prevent half-pixel showing through under nav
    top: 'auto',
    right: 'auto',
    zIndex: 'bottomNav',
    maxWidth: theme.breakpoints.values.sm,
    transform: 'translateX(calc(50vw - 50%))',
    width: '100%',
    opacity: 1,
    transition: 'all .25s ease-in-out',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -1px 10px rgba(0, 0, 0, 0.12)',
    paddingBottom: 'calc(max(var(--sab, 18px) - 18px, 0px))',
    'body.keyboard-open &, body.bottom-sheet-open &, body.share-open &, body.tour-open &, body.auth-open &, body.profile-open &, body.group-open &, body.fullscreen-video &': {
      opacity: 0,
      visibility: 'hidden',
      touchAction: 'none',
      pointerEvents: 'none',
    },
  }), [theme]);

  const topPath = location.pathname.split('/')[1];

  const handleClick = useCallback((ev: SyntheticEvent) => {
    ev.stopPropagation();
    ev.preventDefault();
    const oldPath = window.location.pathname;
    const topPath = oldPath.split('/')[1];
    const subPath = oldPath.split('/')[2];
    const newValue = ev.currentTarget.getAttribute('data-value');

    // No handleChange event when clicking on the current tab, so scroll to top or navigate to top level here instead
    if(topPath === newValue && !subPath) {
      document.querySelectorAll('.visible .scrollContainer').forEach(el => el.scroll({ top: 0, behavior: 'smooth' }))
    } else {
      history.push('/' + newValue);
    }
  }, [history]);

  return (
    <Box
      component="nav"
      sx={appBarStyle}
    >
      <Logo onClick={handleClick} />

      <Box sx={{ display: 'flex' }} className='bottom-nav-tabs'>
        <HomeButton selected={topPath === 'feed'} onClick={handleClick} sx={tabStyle} />
        <SavedButton selected={topPath === 'bookmarks'} onClick={handleClick} sx={tabStyle} />
        <ShareButton selected={topPath === 'share'} onClick={handleClick} sx={tabStyle} />
        <ExploreButton selected={topPath === 'explore'} onClick={handleClick} sx={tabStyle} />
        <FriendsButton selected={topPath === 'groups'} onClick={handleClick} sx={tabStyle} />
      </Box>
    </Box>
  );
});

export default BottomNav;

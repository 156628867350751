import { FC } from 'react';
import { Share as ShareIcon } from 'react-feather';
import { Share } from '@capacitor/share';
import { IconButton, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CanonicalContent, Group, List, Message, Movie, Share as ShareType, TvShow } from '@/generated/graphql';
import { useSession } from '../hooks/auth';
import { useAnalyticsQueued } from '../hooks/delicious-analytics';
import { useLoginPush } from '../hooks/login-push';
import { Button } from "./Button";
import { Loading } from "./Loading";


export type ExternalShareButtonProps = {
  link?: string,
  share?: Pick<ShareType, '_id'> & {
    message?: Pick<Message, 'text'>,
  } | null,
  canonical?: Pick<CanonicalContent, '_id'> & {
    movie?: Pick<Movie, '_id' | 'title'>,
    tvshow?: Pick<TvShow, '_id' | 'title'>,
  } | null,
  group?: Pick<Group, '_id' | 'name'>,
  list?: Pick<List, '_id' | 'name'>,
  loading?: boolean,
  sx?: SxProps,
  variant?: 'contained' | 'icon',
}


export const ExternalShareButton: FC<ExternalShareButtonProps> = function ExternalShareButton({ link, share, canonical, group, list, loading, sx, variant='contained' }) {
  const theme = useTheme();
  const { track } = useAnalyticsQueued();
  const { user } = useSession();
  const { setLoginHint } = useLoginPush();

  const title = share?.message?.text || canonical?.movie?.title || canonical?.tvshow?.title || 'Something delicious';

  const externalShare = async () => {
    if(!user) {
      track('click_external_share_link', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in use the share function.');
      return;
    }
    let text = `Join me at Ice Cream Club 🍦`;
    let trackingLabel = 'invite';
    if (share) {
      trackingLabel = 'share_invite';
      text = `${title} on Ice Cream Club 🍦`;
    } else if (canonical) {
      trackingLabel = 'canonical_invite';
      text = `${title} on Ice Cream Club 🍦`;
    } else if (group) {
      trackingLabel = 'group_invite';
      text = `Join [${group.name}] at Ice Cream Club 🍦`;
    } else if (list) {
      trackingLabel = 'list_invite';
      text = `Check out [${list.name}] at Ice Cream Club 🍦`;
    }
    track('share_invite_link', { category: 'invite', label: trackingLabel });
    try {
      const result = await Share.share({
        title: `${user.name} via Ice Cream Club`, // subject in email, prepended to text in other services
        text: text,
        url: link || 'https://app.icecream.club', // appended to text in most services
        dialogTitle: 'Invite friends', // android only
      });
      console.log(result);
    } catch(e) {
      console.warn(`Native external share failed with error`, e);
    }
  }

  if(variant === 'icon') {
    return (
      <IconButton
        sx={{ color: 'text.primary', ...sx }}
        onClick={externalShare}
      >
        <ShareIcon />
      </IconButton>
    );
  }

  return (
    <Button variant='contained' size='small' onClick={externalShare} sx={{ backgroundColor: theme.palette.grey[300], color: theme.palette.text.primary, ...sx }} disabled={loading}>
      {loading
        ? <Loading style={{ marginRight: 4, width: 16, height: 16 }} />
        : <ShareIcon style={{ marginRight: 4, width: 16, height: 16 }} />
      }
      Send link
    </Button>
  );
}

import { FC, useCallback } from 'react';

import { ErrorBoundary as SentryErrorBoundary, FallbackRender } from "../utils/sentry";
import { Error } from './Error';


export type ErrorBoundaryProps = {
  children: React.ReactNode | React.ReactNode[],
}


export const ErrorBoundary: FC<ErrorBoundaryProps> = function ErrorBoundary({ children }) {

  const fallback = useCallback<FallbackRender>(({ error }) => (
    <Error error={error as object} />
  ), []);

  return (
    <SentryErrorBoundary fallback={fallback}>
      {children}
    </SentryErrorBoundary>
  );
}

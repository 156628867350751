import { FC, memo, SyntheticEvent, useCallback } from "react";
import { Box, Divider } from "@mui/material";
import { StringParam, useQueryParam } from "use-query-params";

import { useAnalyticsQueued } from "@/hooks/delicious-analytics";
import { WatchlistButton, WatchlistButtonProps } from './WatchlistButton';
import { CommentButton, CommentButtonProps } from './CommentButton';
import LikeButton, { LikeButtonProps } from "./LikeButton";
import { ReshareIconButton, ReshareIconButtonProps } from "./ReshareIconButton";


export type ActionIconsProps = {
  share: LikeButtonProps['share'] & WatchlistButtonProps['share'] & ReshareIconButtonProps['share'] & CommentButtonProps['share'],
  canonicalContent: WatchlistButtonProps['canonicalContent'],
  openChatValue: string,
}

type ActionIconsWithParamsProps = ActionIconsProps & { setOpenChatId: (v: string|undefined) => void };


const sheetQueryParam = "comment-chat";


const ActionIconsWithParams: FC<ActionIconsWithParamsProps> = memo(function ActionIconsWithParams({ share, canonicalContent, openChatValue, setOpenChatId }: ActionIconsWithParamsProps) {

  const { track } = useAnalyticsQueued();

  const handleClickComment = useCallback((ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    track('click_feeditem_comment', { category: 'feed' });
    setOpenChatId(openChatValue);
  }, [track, setOpenChatId, openChatValue]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gap: 1.5, mt: 0.5, maxWidth: '280px' }}>
      <LikeButton share={share} sx={{ height: '24px' }} />
      <Divider orientation="vertical" flexItem sx={{ my: '8px' }} />

      <WatchlistButton share={share} canonicalContent={canonicalContent} sx={{ height: '24px' }} />
      <Divider orientation="vertical" flexItem sx={{ my: '8px' }} />

      <ReshareIconButton share={share} sx={{ height: '24px' }} />
      <Divider orientation="vertical" flexItem sx={{ my: '8px' }} />

      <CommentButton share={share} sx={{ height: '24px' }} onClick={handleClickComment} />
    </Box>
  );
});


export const ActionIcons: FC<ActionIconsProps> = function ActionIcons(props) {
  const [, setOpenChatId] = useQueryParam(sheetQueryParam, StringParam);
  return <ActionIconsWithParams {...props} setOpenChatId={setOpenChatId} />;
}


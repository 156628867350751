import { FC, memo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Box, SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useHistory } from 'react-router-dom';

import { useGetFollowsQuery } from '../generated/graphql';
import { useAnalyticsQueued } from '../hooks/delicious-analytics';
import { useFollowings } from '../hooks/following';
import { useSession } from '../hooks/auth';
import { Button } from './Button';
import { Avatar } from './Avatar';
import { Loading } from './Loading';


export type SuggestedFollowsProps = {
  sx?: SxProps
}


export const SuggestedFollows: FC<SuggestedFollowsProps> = memo(function SuggestedFollows({ sx={} }: SuggestedFollowsProps) {
  const theme = useTheme();
  const history = useHistory();
  const { hasIdToken } = useSession();
  const { track } = useAnalyticsQueued();

  const { follow, unfollow } = useFollowings();

  const { data, loading, error } = useGetFollowsQuery({ skip: !hasIdToken });

  if(error) {
    console.error(error);
  }

  if(!data?.currentUser?.followSuggestions?.length) {
    return null;
  }

  const handleSeeAll = () => {
    track('click_see_all', { category: 'suggested_follows' });
    history.push('/groups/find');
  }

  return (
    <Box sx={{
      mt: 2,
      pt: 0,
      pb: 1.5,
      ...sx,
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', px: 2 }}>
        <Typography variant='h3'>
          Suggestions for You
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant='text' size='small' onClick={handleSeeAll} sx={{ p: 0 }}>
          See all
        </Button>
      </Box>
      <Box sx={{
        display: 'flex',
        overflowX: 'scroll',
        ml: '-4px',
      }}>
        <AnimatePresence>
          {loading &&
            <Loading />
          }
          {data?.currentUser && data.currentUser?.followSuggestions?.slice(0, 8).map((contact, index) => {
            const followed = data.currentUser?.follows?.some(f => f._id === contact._id) || false;

            return (
              <motion.div style={{ paddingLeft: index === 0 ? 16 : 0 }} key={contact._id} animate={{ y: 0, opacity: 1 }} exit={{ y: -200, opacity: 0 }}>
                <Box key={contact._id} sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '0 0 auto',
                  alignItems: 'center',
                  width: 136,
                  p: 1,
                  backgroundColor: theme.palette.grey['100'],
                  margin: '4px',
                  border: `1px solid ${theme.palette.grey['200']}`,
                  borderRadius: '8px'
                }}>
                  <Link to={`/@${contact.username}`} style={{ textAlign: 'center', overflowWrap: 'anywhere' }}>
                    <Avatar
                      size='xlarge'
                      sx={{ mt: 1, mb: 1.5 }}
                      contact={contact}
                    />
                  </Link>
                  <Link to={`/@${contact.username}`} style={{ textAlign: 'center', maxWidth: '100%' }}>
                    <Typography variant='h4' noWrap sx={{ mb: 0.5 }}>
                      {contact.name}
                    </Typography>
                    <Typography variant='h4' noWrap sx={{ mb: 1.5, color: theme.palette.text.secondary }}>
                      @{contact.username}
                    </Typography>

                  </Link>
                  <Button
                    variant={followed ? 'outlined' : 'contained'}
                    size='small'
                    color="primary"
                    onClick={() => {
                      if (followed) {
                        track('unfollow', { category: 'feed', origin: 'suggested_follows' });
                        unfollow(contact);
                      } else {
                        track('follow', { category: 'feed', origin: 'suggested_follows' });
                        follow(contact)
                      }
                    }}
                    sx={{ mb: 1 }}
                  >
                    {followed ? 'Unfollow' : 'Follow'}
                  </Button>
                </Box>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </Box>

    </Box>
  )
});
